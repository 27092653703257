<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Contrats"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>

            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="contratsSimples"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(locataire.firstname)="data">
            <div>

              {{ data.item.locataire.firstname }} {{ data.item.locataire.lastname }}

            </div>
          </template>
          <template #cell(type_contrat.title)="data">
            <div>

              {{ data.item.type_contrat.title }}

            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${statusContratVariant(data.item.status)}`"
              >
                {{ data.item.status }}

              </b-badge>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Voir le détail du contrat'"
              variant="gradient-primary"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              :to="{ name: 'espace-coproprietaire.detail-contrats', params: { id: data.item.id }, replace: true }"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Prévisualiser le contrat'"
              v-b-modal.modal-preview
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier un contrat'"
              v-b-modal.modal-coproprieted
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              :disabled="data.item.edit_authorize !==1"
              :to="{ name: 'espace-coproprietaire.modifier-contrat', params: { id: data.item.id }, replace: true }"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Demander modification'"
              variant="gradient-success"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="askModification(data.item)"
            >
              <feather-icon icon="PenToolIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="contratsSimples.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="modal-preview"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      modal-class="modal-success"
      title="Le contrat"
      cancel-title="Fermer"
      cancel-variant="danger"
      size="lg"
    >
      <b-row>
        <b-col
          md="12"
        >
          <embed
            :src="`${SITE_URL}${pdfFile}`"
            type="application/pdf"
            width="100%"
            height="600px"
          >
        </b-col>
      </b-row>
    </b-modal>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BBadge, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BSpinner, VBModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const { SITE_URL } = $themeConfig.app

    const {
      getContractSimple, contratsSimples, loader, askAuthorizationContrat,

    } = useProprietes()

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'locataire.firstname', label: 'Locataires', sortable: true },
      { key: 'type_contrat.title', label: 'Type du contrat', sortable: true },
      { key: 'date_debut_bail', label: 'Date début', sortable: true },
      { key: 'date_fin_bail', label: 'Date Fin', sortable: true },
      { key: 'status', label: 'Statut du contrat', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      name: '',
      societe_id: '',
      modele_economique_id: '',
      description: '',
    })
    /* eslint-disable global-require */

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    const statusContratVariant = status => {
      if (status === 'En attente') return 'primary'
      if (status === 'Actif') return 'success'
      return 'warning'
    }

    onMounted(async () => {
      await getContractSimple()
      // Set the initial number of items
      totalRows.value = contratsSimples.value.length
    })
    const pdfFile = ref('')

    // Récupérer un enregistrement
    const getItem = async item => {
      pdfFile.value = item.fichier_contrat_url
    }

    const contractForm = reactive({
      id: null,
      type_contrat_id: '',
    })
    const askModification = async contrat => {
      contractForm.id = contrat.id
      contractForm.type_contrat_id = contrat.type_contrat_id
      Swal.fire({
        title: 'Demande de modification',
        text: 'Etes-vous sûr de vouloir demander la modification de ce contrat ?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Confirmer',
      }).then(async result => {
        if (result.isConfirmed) {
          await askAuthorizationContrat(contrat.id, contractForm)
          await getContractSimple()
          Swal.fire({

            title: 'Demande!',
            text: 'Demande envoyée avec succès.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }

    return {
      contratsSimples,
      sortOptions,
      statusContratVariant,
      loader,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      fields,
      onFiltered,
      form,
      formatter,
      askModification,
      SITE_URL,
      getItem,
      pdfFile,

    }
  },

}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
